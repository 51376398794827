<template>
  <v-container>
    <div>
      <div style="margin-top:10px">
        <v-alert
          :value="alert"
          color="info"
          dark
          border="top"
          icon="mdi-information-outline"
          transition="scale-transition"
        >
          Met het
          <v-icon color="purple" dark> mdi-pencil </v-icon>incoon, kun je een
          locatie bewerken <br />

          met het
          <v-icon color="purple" dark>
            mdi-trash-can
          </v-icon>
          icoon, kun je een locatie verwijderen <br />

          Met het
          <v-icon color="purple" dark>
            mdi-alarm
          </v-icon>
          icoon, kun je een out of office per locatie instellen.<br />
          voor vragen kun je een mail sturen naar
          <strong>info@kantoorflex.nl</strong>
        </v-alert>
      </div>
      <v-dialog v-model="dialogCompose" width="500">
        <v-card>
          <v-card-title
            class="primary justify-center"
            style="color:white"
            primary-title
          >
            Out of Office instellen
            <v-icon dark>
              mdi-alarm
            </v-icon>
          </v-card-title>
          <v-card-text class="pa-5">
            <v-form lazy-validation>
              <!-- Van-->
              <v-col>
                <v-menu
                  ref="menu1"
                  v-model="menu1"
                  :close-on-content-click="false"
                  transition="scale-transition"
                  offset-y
                  max-width="290px"
                  min-width="auto"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                      v-model="dateFormatted"
                      @blur="startdate = parseDate(dateFormatted)"
                      label="Startdatum"
                      hint="DD/MM/YYYY format"
                      persistent-hint
                      prepend-icon="mdi-calendar"
                      v-bind="attrs"
                      v-on="on"
                    ></v-text-field>
                  </template>
                  <v-date-picker
                    v-model="startdate"
                    no-title
                    @input="menu1 = false"
                  ></v-date-picker>
                </v-menu>
              </v-col>

              <!-- tot -->

              <v-col>
                <v-menu
                  ref="menu2"
                  v-model="menu2"
                  :close-on-content-click="false"
                  transition="scale-transition"
                  offset-y
                  max-width="290px"
                  min-width="auto"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                      v-model="enddateFormatted"
                      @blur="enddate = parseEndDate(enddateFormatted)"
                      label="Enddatum invoeren"
                      hint="DD/MM/YYYY format"
                      persistent-hint
                      prepend-icon="mdi-calendar"
                      v-bind="attrs"
                      v-on="on"
                    ></v-text-field>
                  </template>
                  <v-date-picker
                    v-model="enddate"
                    no-title
                    @input="menu2 = false"
                  ></v-date-picker>
                </v-menu>
              </v-col>
            </v-form>
          </v-card-text>
          <v-card-actions class="pa-5">
            <v-btn @click="instellen()" outlined color="primary"
              >Instellen</v-btn
            >
            <v-btn @click="cancel" outlined color="error">Afsluiten</v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </div>
    <div
      class="text-center"
      style="float:left;margin-right:20px;padding-top:20px"
    >
      <v-row>
        <div>
          <v-btn class="ma-2" color="primary" @click="goToAddOffice()">
            Kantoor toevoegen
            <v-icon dark right>
              mdi-cloud-upload
            </v-icon>
          </v-btn>

          <v-btn color="primary" @click="alert = !alert">
            {{ alert ? "Sluiten" : "Handige informatie" }}
          </v-btn>
        </div>
      </v-row>
    </div>

    <div class="workspaces">
      <v-row>
        <v-col
          v-for="workspace in workspaces"
          :key="workspace.office_id"
          class="d-flex flex-column"
          sm="6"
          md="4"
          lg="3"
        >
          <v-hover v-slot="{ hover }">
            <v-card
              rounded
              class="pb-2 flex d-flex flex-column"
              :elevation="hover ? 24 : 2"
              :class="{ 'on-hover': hover }"
            >
              <v-img
                :src="
                  workspace.images[0]
                    ? `https://server.kantoorflex.nl/static/${workspace.images[0].filename}`
                    : require('@/assets/logo.png')
                "
                img-alt="Image"
                img-top
                tag="article"
                class="h-100 card-image body-1"
              >
                <v-card-title
                  class="py-0 mb-3"
                  style="color:white;font-weight:bold"
                >
                  {{ workspace.officeName }}</v-card-title
                >
                <v-card-subtitle class="pb-0 mb-2" style="color: black">
                  {{ workspace.adres }}</v-card-subtitle
                >
              </v-img>
              <v-fade-transition>
                <v-overlay v-if="hover" absolute color="blue">
                  <v-btn
                    class="mx-2"
                    fab
                    dark
                    small
                    color="cyan"
                    :to="`/Flexplekwijzigen/${workspace.office_id}`"
                  >
                    <v-icon dark>
                      mdi-pencil
                    </v-icon>
                  </v-btn>
                  <v-btn
                    class="mx-2"
                    fab
                    dark
                    small
                    color="warning"
                    @click="outOfOffice(workspace.office_id)"
                  >
                    <v-icon dark>
                      mdi-alarm
                    </v-icon>
                  </v-btn>
                  <v-btn
                    class="mx-2"
                    fab
                    dark
                    small
                    color="error"
                    @click="deleteOffice(workspace.office_id)"
                  >
                    <v-icon dark>
                      mdi-trash-can
                    </v-icon>
                  </v-btn>
                </v-overlay>
              </v-fade-transition>
            </v-card>
          </v-hover>
        </v-col>
      </v-row>
    </div>
  </v-container>
</template>

<script>
import axios from "axios";
import apiOwner from "../api/apiOffice";
import apiOffice from "../api/apiOffice";
export default {
  data: (vm) => ({
    startdate: new Date(Date.now() - new Date().getTimezoneOffset() * 60000)
      .toISOString()
      .substr(0, 10),
    enddate: new Date(Date.now() - new Date().getTimezoneOffset() * 60000)
      .toISOString()
      .substr(0, 10),

    dateFormatted: vm.formatDate(
      new Date(Date.now() - new Date().getTimezoneOffset() * 60000)
        .toISOString()
        .substr(0, 10)
    ),

    enddateFormatted: vm.formatDate(
      new Date(Date.now() - new Date().getTimezoneOffset() * 60000)
        .toISOString()
        .substr(0, 10)
    ),
    menu1: false,
    menu2: false,

    workspaces: [],
    show: false,
    dialogCompose: false,
    alert: false,
  }),
  computed: {
    computedDateFormatted() {
      return this.formatDate(this.startdate);
    },
    computedEndDateFormatted() {
      return this.formatDate(this.enddate);
    },
  },

  watch: {
    startdate() {
      this.dateFormatted = this.formatDate(this.startdate);
    },
    enddate() {
      this.enddateFormatted = this.formatDate(this.enddate);
    },
  },

async mounted() {
  try {
    const data = await apiOwner.getOwnerOffices();
    this.workspaces = data;
  } catch (error) {
    console.error(error);
  }
},
  methods: {
    formatDate(startdate) {
      if (!startdate) return null;

      const [year, month, day] = startdate.split("-");
      return `${day}/${month}/${year}`;
    },
    parseDate(startdate) {
      if (!startdate) return null;

      const [year, month, day] = startdate.split("/");
      return `${day.padStart(2, "0")}-${month.padStart(2, "0")}-${year}`;
    },

    formatEndDate(enddate) {
      if (!enddate) return null;

      const [year, month, day] = enddate.split("-");
      return `${day}/${month}/${year}`;
    },
    parseEndDate(enddate) {
      if (!enddate) return null;

      const [year, month, day] = enddate.split("/");
      return `${day.padStart(2, "0")}-${month.padStart(2, "0")}-${year}`;
    },

    async instellen(office_id) {
      await axios.post(
        `https://server.kantoorflex.nl/api/rest/v2/office/${office_id}/outofoffice`,
        {
          startDate: this.startdate,
          endDate: this.enddate,
        }
      );
    },

    outOfOffice() {
      this.dialogCompose = true;
    },
goToAddOffice(){
  this.$router.push('/Flexplektoevoegen');
},
    cancel() {
      this.dialogCompose = false;
    },

   async deleteOffice(officeId) {
    const confirmed = await this.$swal.fire({
      title: "Weet je zeker dat je deze flexplek wilt verwijderen?",
      text: "Je kunt deze actie niet ongedaan maken",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Ja, verwijderen",
      cancelButtonText: "Annuleren",
    });

    if (confirmed.value) {
      try {
        await apiOffice.deleteOffice(officeId);
        const data = await apiOwner.getAllMyOffices();
        this.workspaces = data;
        this.$swal.fire(
          "Verwijderd",
          "Flexplek is succesvol verwijderd",
          "success"
        );
      } catch (error) {
        console.error(error);
      }
    }
  },
}
};
</script>

<style scoped>
.workspaces {
  margin-top: 100px;
}

.link {
  color: rgba(79, 89, 98);
  text-decoration: none;
  transition: color 300ms ease-in-out;
}
.link:hover {
  color: rgba(79, 89, 98, 0.5);
}
.card-image-link {
  overflow: hidden;
  cursor: pointer;
}
.card-image {
  transition: 300ms;
}
.card-image:hover {
  transform: scale(1.2);
}
</style>
