var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',[_c('div',[_c('div',{staticStyle:{"margin-top":"10px"}},[_c('v-alert',{attrs:{"value":_vm.alert,"color":"info","dark":"","border":"top","icon":"mdi-information-outline","transition":"scale-transition"}},[_vm._v(" Met het "),_c('v-icon',{attrs:{"color":"purple","dark":""}},[_vm._v(" mdi-pencil ")]),_vm._v("incoon, kun je een locatie bewerken "),_c('br'),_vm._v(" met het "),_c('v-icon',{attrs:{"color":"purple","dark":""}},[_vm._v(" mdi-trash-can ")]),_vm._v(" icoon, kun je een locatie verwijderen "),_c('br'),_vm._v(" Met het "),_c('v-icon',{attrs:{"color":"purple","dark":""}},[_vm._v(" mdi-alarm ")]),_vm._v(" icoon, kun je een out of office per locatie instellen."),_c('br'),_vm._v(" voor vragen kun je een mail sturen naar "),_c('strong',[_vm._v("info@kantoorflex.nl")])],1)],1),_c('v-dialog',{attrs:{"width":"500"},model:{value:(_vm.dialogCompose),callback:function ($$v) {_vm.dialogCompose=$$v},expression:"dialogCompose"}},[_c('v-card',[_c('v-card-title',{staticClass:"primary justify-center",staticStyle:{"color":"white"},attrs:{"primary-title":""}},[_vm._v(" Out of Office instellen "),_c('v-icon',{attrs:{"dark":""}},[_vm._v(" mdi-alarm ")])],1),_c('v-card-text',{staticClass:"pa-5"},[_c('v-form',{attrs:{"lazy-validation":""}},[_c('v-col',[_c('v-menu',{ref:"menu1",attrs:{"close-on-content-click":false,"transition":"scale-transition","offset-y":"","max-width":"290px","min-width":"auto"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-text-field',_vm._g(_vm._b({attrs:{"label":"Startdatum","hint":"DD/MM/YYYY format","persistent-hint":"","prepend-icon":"mdi-calendar"},on:{"blur":function($event){_vm.startdate = _vm.parseDate(_vm.dateFormatted)}},model:{value:(_vm.dateFormatted),callback:function ($$v) {_vm.dateFormatted=$$v},expression:"dateFormatted"}},'v-text-field',attrs,false),on))]}}]),model:{value:(_vm.menu1),callback:function ($$v) {_vm.menu1=$$v},expression:"menu1"}},[_c('v-date-picker',{attrs:{"no-title":""},on:{"input":function($event){_vm.menu1 = false}},model:{value:(_vm.startdate),callback:function ($$v) {_vm.startdate=$$v},expression:"startdate"}})],1)],1),_c('v-col',[_c('v-menu',{ref:"menu2",attrs:{"close-on-content-click":false,"transition":"scale-transition","offset-y":"","max-width":"290px","min-width":"auto"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-text-field',_vm._g(_vm._b({attrs:{"label":"Enddatum invoeren","hint":"DD/MM/YYYY format","persistent-hint":"","prepend-icon":"mdi-calendar"},on:{"blur":function($event){_vm.enddate = _vm.parseEndDate(_vm.enddateFormatted)}},model:{value:(_vm.enddateFormatted),callback:function ($$v) {_vm.enddateFormatted=$$v},expression:"enddateFormatted"}},'v-text-field',attrs,false),on))]}}]),model:{value:(_vm.menu2),callback:function ($$v) {_vm.menu2=$$v},expression:"menu2"}},[_c('v-date-picker',{attrs:{"no-title":""},on:{"input":function($event){_vm.menu2 = false}},model:{value:(_vm.enddate),callback:function ($$v) {_vm.enddate=$$v},expression:"enddate"}})],1)],1)],1)],1),_c('v-card-actions',{staticClass:"pa-5"},[_c('v-btn',{attrs:{"outlined":"","color":"primary"},on:{"click":function($event){return _vm.instellen()}}},[_vm._v("Instellen")]),_c('v-btn',{attrs:{"outlined":"","color":"error"},on:{"click":_vm.cancel}},[_vm._v("Afsluiten")])],1)],1)],1)],1),_c('div',{staticClass:"text-center",staticStyle:{"float":"left","margin-right":"20px","padding-top":"20px"}},[_c('v-row',[_c('div',[_c('v-btn',{staticClass:"ma-2",attrs:{"color":"primary"},on:{"click":function($event){return _vm.goToAddOffice()}}},[_vm._v(" Kantoor toevoegen "),_c('v-icon',{attrs:{"dark":"","right":""}},[_vm._v(" mdi-cloud-upload ")])],1),_c('v-btn',{attrs:{"color":"primary"},on:{"click":function($event){_vm.alert = !_vm.alert}}},[_vm._v(" "+_vm._s(_vm.alert ? "Sluiten" : "Handige informatie")+" ")])],1)])],1),_c('div',{staticClass:"workspaces"},[_c('v-row',_vm._l((_vm.workspaces),function(workspace){return _c('v-col',{key:workspace.office_id,staticClass:"d-flex flex-column",attrs:{"sm":"6","md":"4","lg":"3"}},[_c('v-hover',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
var hover = ref.hover;
return [_c('v-card',{staticClass:"pb-2 flex d-flex flex-column",class:{ 'on-hover': hover },attrs:{"rounded":"","elevation":hover ? 24 : 2}},[_c('v-img',{staticClass:"h-100 card-image body-1",attrs:{"src":workspace.images[0]
                  ? ("https://server.kantoorflex.nl/static/" + (workspace.images[0].filename))
                  : require('@/assets/logo.png'),"img-alt":"Image","img-top":"","tag":"article"}},[_c('v-card-title',{staticClass:"py-0 mb-3",staticStyle:{"color":"white","font-weight":"bold"}},[_vm._v(" "+_vm._s(workspace.officeName))]),_c('v-card-subtitle',{staticClass:"pb-0 mb-2",staticStyle:{"color":"black"}},[_vm._v(" "+_vm._s(workspace.adres))])],1),_c('v-fade-transition',[(hover)?_c('v-overlay',{attrs:{"absolute":"","color":"blue"}},[_c('v-btn',{staticClass:"mx-2",attrs:{"fab":"","dark":"","small":"","color":"cyan","to":("/Flexplekwijzigen/" + (workspace.office_id))}},[_c('v-icon',{attrs:{"dark":""}},[_vm._v(" mdi-pencil ")])],1),_c('v-btn',{staticClass:"mx-2",attrs:{"fab":"","dark":"","small":"","color":"warning"},on:{"click":function($event){return _vm.outOfOffice(workspace.office_id)}}},[_c('v-icon',{attrs:{"dark":""}},[_vm._v(" mdi-alarm ")])],1),_c('v-btn',{staticClass:"mx-2",attrs:{"fab":"","dark":"","small":"","color":"error"},on:{"click":function($event){return _vm.deleteOffice(workspace.office_id)}}},[_c('v-icon',{attrs:{"dark":""}},[_vm._v(" mdi-trash-can ")])],1)],1):_vm._e()],1)],1)]}}],null,true)})],1)}),1)],1)])}
var staticRenderFns = []

export { render, staticRenderFns }