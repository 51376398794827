<template>
  <div>
    <v-divider> </v-divider>
    <v-container>
      <v-row align="center" justify="center" class="space">
        <v-col cols="12" sm="10">
          <v-card class="elevation-6 mt-10">
            <v-window v-model="step">
              <v-window-item :value="1">
                <v-row>
                  <v-col cols="12" md="6">
                    <v-card-text class="mt-12">
                      <h4 class="text-center"><b>Inloggen op portaal</b></h4>
                      <h6 class="text-center grey--text">
                        Log in en beheer jouw eigen plaatsingen
                      </h6>
                      <v-row align="center" justify="center">
                        <v-col cols="12" sm="8">
                          <v-text-field
                            label="E-mail"
                            outlined
                            dense
                            color="blue"
                            autocomplete="false"
                            class="mt-16"
                            v-model="email"
                          />

                          <v-text-field
                            :append-icon="show3 ? 'mdi-eye' : 'mdi-eye-off'"
                            :type="show3 ? 'text' : 'password'"
                            label="Wachtwoord"
                            outlined
                            dense
                            color="blue"
                            autocomplete="false"
                            class="input-group--focused"
                            v-model="password"
                            @click:append="show3 = !show3"
                          />

                          <v-btn
                            color="blue"
                            dark
                            block
                            tile
                            @click="userLogin()"
                            autocomplete="false"
                            >Inloggen</v-btn
                          >

                          <v-col cols="12" sm="8">
                            <span
                              class="caption blue--text"
                              @click="resetPassword()"
                              >Wachtwoord vergeten</span
                            >
                          </v-col>
                        </v-col>
                      </v-row>
                    </v-card-text>
                  </v-col>
                  <v-col cols="12" md="6" class="blue rounded-bl-xl">
                    <div style="text-align: center; padding: 180px 0">
                      <v-card-text class="white--text">
                        <h3 class="text-center">Nog geen account?</h3>
                        <h6 class="text-center">
                          Maak vandaag een account aan en begin met het
                          plaatsten of zoeken van een werkplek
                        </h6>
                      </v-card-text>
                      <div class="text-center">
                        <v-btn tile outlined dark @click="step++"
                          >Aanmaken</v-btn
                        >
                      </div>
                    </div>
                  </v-col>
                </v-row>
              </v-window-item>
              <v-window-item :value="2">
                <v-row>
                  <v-col cols="12" md="6" class="blue rounded-br-xl">
                    <div style="text-align: center; padding: 180px 0">
                      <v-card-text class="white--text">
                        <h3 class="text-center">Al een account?</h3>
                        <h6 class="text-center">
                          Log dan in met jouw gegevens
                        </h6>
                      </v-card-text>
                      <div class="text-center">
                        <v-btn tile outlined dark @click="step--"
                          >Inloggen</v-btn
                        >
                      </div>
                    </div>
                  </v-col>

                  <v-col cols="12" md="6">
                    <v-card-text class="mt-12">
                      <h4 class="text-center">Maak een account aan</h4>
                      <h6 class="text-center grey--text">
                        Maak een account aan door onderstaand in te vullen
                      </h6>
                      <v-row align="center" justify="center">
                        <v-col cols="12" sm="8">
                          <v-row>
                            <v-col cols="12" sm="6">
                              <v-text-field
                                label="Voornaam"
                                outlined
                                v-model="form.voornaam"
                                dense
                                color="blue"
                                autocomplete="false"
                                class="mt-4"
                              />
                            </v-col>
                            <v-col cols="12" sm="6">
                              <v-text-field
                                label="Achternaam"
                                outlined
                                v-model="form.achternaam"
                                dense
                                color="blue"
                                autocomplete="false"
                                class="mt-4"
                              />
                            </v-col>
                          </v-row>
                          <v-text-field
                            label="E-mail"
                            outlined
                            v-model="form.email"
                            dense
                            color="blue"
                            autocomplete="false"
                          />
                          <v-text-field
                            label="Telefoonnummer"
                            outlined
                            v-model="form.phone"
                            dense
                            color="blue"
                            autocomplete="false"
                          />
                          <v-text-field
                            label="Wachtwoord"
                            v-model="form.password"
                            outlined
                            dense
                            color="blue"
                            autocomplete="false"
                            type="password"
                          />

                          <v-select
                            dense
                            color="blue"
                            v-model="form.type"
                            :items="items"
                            label="Selecteer"
                            outlined
                          ></v-select>

                          <v-row>
                            <v-col cols="12" sm="6">
                              <v-checkbox
                                label="Ik accepteer"
                                class="mt-n1"
                                color="blue"
                              >
                              </v-checkbox>
                            </v-col>

                            <v-col cols="12" sm="6">
                              <span class="caption blue--text ml-n4">
                                <router-link to="/Algemenevoorwaarden"
                                  >Algemene voorwaarden</router-link
                                ></span
                              >
                            </v-col>
                          </v-row>
                          <v-btn
                            color="blue"
                            dark
                            block
                            tile
                            v-if="form.type == 'Ik ben een gebruiker'"
                            @click="registrationCustomer()"
                            >Account aanmaken</v-btn
                          >

                          <v-btn
                            color="blue"
                            dark
                            block
                            tile
                            v-if="form.type == 'Ik ben een locatiehouder'"
                            @click="registrationMakelaar()"
                            >Account aanmaken</v-btn
                          >
                          <v-btn
                            color="blue"
                            dark
                            block
                            tile
                            v-if="form.type == ''"
                            @click="melding()"
                            >Account aanmaken 3</v-btn
                          >

                          <!-- <h5 class="text-center grey--text mt-4 mb-3">
                            Of log in met
                          </h5>
                          <div
                            class="
                              d-flex
                              justify-space-between
                              align-center
                              mx-10
                              mb-11
                            "
                          >
                            <v-btn depressed outlined color="grey">
                              <v-icon color="red">fab fa-google</v-icon>
                            </v-btn>
                            <v-btn depressed outlined color="grey">
                              <v-icon color="blue">fab fa-facebook-f</v-icon>
                            </v-btn>
                            <v-btn depressed outlined color="grey">
                              <v-icon color="light-blue lighten-3"
                                >fab fa-twitter</v-icon
                              >
                            </v-btn>
                          </div> -->
                        </v-col>
                      </v-row>
                    </v-card-text>
                  </v-col>
                </v-row>
              </v-window-item>
            </v-window>
          </v-card>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
import firebase from "firebase";
import axios from "axios";
export default {
  data: () => ({
    show3: false,
    step: 1,
    items: ["Ik ben een gebruiker", "Ik ben een locatiehouder"],
    email: "",
    password: "",
    form: {
      voornaam: "",
      achternaam: "",
      email: "",
      phone: "",
      password: "",
      type: "",
    },
  }),
  props: {
    source: String,
  },
  methods: {
    melding() {
      this.$swal.fire({
        icon: "error",
        title: "Oops...",
        text: "controleer of alle velden ingevuld!",
      });
    },
    userLogin() {
      const Toast = this.$swal.mixin({
        toast: true,
        position: "top-end",
        showConfirmButton: false,
        timer: 3000,
        timerProgressBar: true,
        didOpen: (toast) => {
          toast.addEventListener("mouseenter", this.$swal.stopTimer);
          toast.addEventListener("mouseleave", this.s$swal.resumeTimer);
        },
      });
      firebase
        .auth()
        .signInWithEmailAndPassword(this.email, this.password)
        .then(async () => {
          // this.$router.push("/Dashboard");
          const token = await firebase.auth().currentUser.getIdToken(true);
          if (token) axios.defaults.headers.common["fireToken"] = token;
          const response = await axios.get(`https://server.kantoorflex.nl/api/rest/v2/whoami`);
          if (["CUSTOMER"].includes(response.data.accessRight)) {
            this.$router.push("/KlantPortaal");
          }
          if (["ADMIN", "WERKNEMER"].includes(response.data.accessRight)) {
            this.$router.push("/AdminPortaal");
          }
          if (["MAKELAAR"].includes(response.data.accessRight)) {
            this.$router.push("/Dashboard");
          }
          if (["UNDETERMINED"].includes(response.data.accessRight)) {
            this.$router.push("/Inloggen");
            Toast.fire({
              icon: "error",
              title: "Rechten onbekend! Neem contact op met een beheerder.",
            });
            firebase.auth().signOut();
            return;
          }

          Toast.fire({
            icon: "success",
            title: "Succesvol ingelogd",
          });
        })
        //.catch((error) => {
        .catch(() => {
          this.$swal.fire({
            icon: "error",
            title: "Oops...",
            text: "Gebruikersnaam of wachtwoord is onjuist.!",
            footer:
              '<a href="mailto:info@kantoorflex.nl?subject=Inloggen%20lukt%20niet%20op%20de%20website">Hulp nodig?</a>',
          });
        });
    },
    resetPassword() {
      firebase
        .auth()
        .sendPasswordResetEmail(this.email)
        .then(() => {
          this.$swal.fire({
            position: "top-end",
            icon: "success",
            title: "Wachtwoord link verzonden",
            showConfirmButton: false,
            timer: 1500,
          });
        })
        .catch(() => {
          this.$swal.fire("Bericht is verzonden");
        });
    },

    async registrationMakelaar() {
      let voornaam = !!this.form.voornaam;
      let achternaam = !!this.form.achternaam;
      let email = !!this.form.email;
      let phone = !!this.form.phone;
      let password = !!this.form.password;

      let formisValid = voornaam && achternaam && email && phone && password;
      if (formisValid) {
        await axios.post(`https://server.kantoorflex.nl/api/rest/v2/makelaar`, {
          name: this.form.voornaam + this.form.achternaam,
          email: this.form.email,
          phoneNumber: this.form.phone,
          password: this.form.password,
        });
        this.$swal.fire(
          "Dankje!",
          "Successvol geregistreerd! U kunt inloggen",
          "success"
        );
        this.clear();
      } else {
        this.$swal.fire(
          "Error!",
          "Controleer of alle velden zijn ingevuld.",
          "error"
        );
      }
    },

    async registrationCustomer() {
      let voornaam = !!this.form.voornaam;
      let achternaam = !!this.form.achternaam;
      let email = !!this.form.email;
      let phone = !!this.form.phone;
      let password = !!this.form.password;

      let formisValid = voornaam && achternaam && email && phone && password;
      if (formisValid) {
        await axios.post(`https://server.kantoorflex.nl/api/rest/v2/customer`, {
          name: this.form.voornaam + this.form.achternaam,
          email: this.form.email,
          phoneNumber: this.form.phone,
          password: this.form.password,
        });
        this.$swal.fire(
          "Dankje!",
          "Successvol geregistreerd! U kunt inloggen",
          "success"
        );
        this.clear();
      } else {
        this.$swal.fire(
          "Error!",
          "Controleer of alle velden zijn ingevuld.",
          "error"
        );
      }
    },
  },
};
</script>
<style scoped>
.v-application .rounded-bl-xl {
  border-bottom-left-radius: 300px !important;
}
.v-application .rounded-br-xl {
  border-bottom-right-radius: 300px !important;
}
.space {
  margin-bottom: 2px;
}
</style>
