<template>



  <LoginPage />


</template>

<script>

import LoginPage from "@/components/view/LoginPage";

export default {
  data() {
    return {
      data: [],
    };
  },
  components: {

    LoginPage,
 
  },
  metaInfo: {
    title: "Welkom bij KantoorFlex | flexplekken in Nederland ",
    htmlAttrs: {
      lang: "EN",
      amp: true,
    },
  },
};
</script>