<template>
  <div class="space">
    <v-container>
      <v-card elevation="20" outlined>
        <v-row>
          <v-card-title class="officeName">Basis informatie</v-card-title>
        </v-row>
        <v-divider color="primary"></v-divider>

        <v-row class="justify-center">
          <v-col col="10" sm="10">
            <v-text-field
              v-model="office.officeName.value"
              outlined
              label="Titel"
              :class="{
                'is-valid':
                  office.officeName.value.length > 0 && has_minimum_length,
                'is-invalid':
                  office.officeName.value.length > 0 && !has_minimum_length,
              }"
            />
            <template v-if="office.officeName.value.length > 0">
              <div class="text-danger small" v-show="!has_minimum_length">
                - Naam ruimte moet minimaal 4 karakters hebben
              </div>
            </template>
          </v-col>
        </v-row>
        <v-row class="justify-center">
          <v-col col="5" sm="5">
            <v-select
              v-model="office.categories"
              :items="categories"
              item-value="categorie_id"
              item-text="categorie"
              multiple
              outlined
              label="Selecteer de categorie"
            ></v-select>
          </v-col>
          <v-col col="5" sm="5">
            <v-select
              v-model="office.facilities"
              :items="facilities"
              item-value="facilitie_id"
              item-text="facilitie"
              multiple
              outlined
              label="Selecteer de faciliteiten"
            ></v-select>
          </v-col>
        </v-row>
      </v-card>
      <v-card class="cards" elevation="20" outlined>
        <v-row>
          <v-card-title class="officeName">locatie en prijs </v-card-title>
        </v-row>
        <v-divider color="primary"></v-divider>

        <v-row class="justify-center">
          <v-col col="5" sm="5">
            <v-card outlined>
              <gmap-autocomplete
                class="col-12"
                placeholder="Adres toevoegen"
                @place_changed="Data"
                required
              >
              </gmap-autocomplete>
            </v-card>
          </v-col>

          <v-col col="5" sm="5">
            <v-text-field
              v-model="office.price.value"
              prepend-inner-icon="mdi-currency-eur"
              outlined
              label="Prijs"
              required
            />
          </v-col>
        </v-row>

        <v-row class="justify-center">
          <v-col cols="5" sm="5">
            <v-text-field
              v-model="office.total"
              type="number"
              prepend-inner-icon="mdi-chair-rolling"
              outlined
              label="Aantal werkplekken"
            />
          </v-col>

          <v-col cols="5" sm="5">
            <v-text-field
              v-model="office.square"
              type="number"
              prepend-inner-icon="mdi-set-square"
              outlined
              label="Aantal vierkante meter"
            />
          </v-col>
        </v-row>
      </v-card>

      <v-card class="cards" elevation="20" outlined>
        <v-row>
          <v-card-title class="officeName">Content</v-card-title>
        </v-row>

        <v-divider color="primary"></v-divider>

        <v-row class="justify-center">
          <v-col col="5" sm="5">
            <v-text-field
              v-model="office.youtube"
              prepend-inner-icon="mdi-youtube"
              outlined
              label="Youtube link"
            />
          </v-col>

          <v-col col="5" sm="5">
            <v-text-field
              v-model="office.facebook"
              prepend-inner-icon="mdi-facebook"
              outlined
              label="Facebook link"
            />
          </v-col>
        </v-row>

        <v-row class="justify-center">
          <v-col col="5" sm="5">
            <v-text-field
              v-model="office.instagram"
              prepend-inner-icon="mdi-instagram"
              outlined
              label="Instagram link"
            />
          </v-col>

          <v-col col="5" sm="5">
            <v-text-field
              v-model="office.twitter"
              prepend-inner-icon="mdi-twitter"
              outlined
              label="twitter link"
            />
          </v-col>
        </v-row>

        <v-row
          class="justify-center"
          style="margin-left: 90px; margin-bottom: 30px"
        >
          <v-col>
            <label class="typo__label">Afbeelding toevoegen </label>
            <div
              id="my-strictly-unique-vue-upload-multiple-image"
              style="display: flex; justify-content: left"
            >
              <vue-upload-multiple-image
                @upload-success="uploadImageSuccess"
                @before-remove="beforeRemove"
                @edit-image="editImage"
                :max-image="20"
                :data-images="images"
                idUpload="myIdUpload"
                editUpload="myIdEdit"
                dragText="Sleep afbeeldingen"
                browseText="of klik om te selecteren"
                dropText="Zet uw bestanden hier neer"
                markIsPrimaryText="Instellen als standaard"
                primaryText="standaard"
                popupText="Dit is de standaard afbeelding"
              ></vue-upload-multiple-image>
            </div>
          </v-col>
        </v-row>
      </v-card>

      <v-card class="cards" elevation="20" outlined>
        <v-row>
          <v-card-title class="officeName">Extra informatie</v-card-title>
        </v-row>

        <v-divider color="primary"></v-divider>

        <v-row class="justify-center">
          <v-col col="10" sm="10">
            <v-textarea
              v-model="office.description"
              clearable
              clear-icon="mdi-close-circle"
              label="Text"
              value="extra informatie voor de klant."
            ></v-textarea>
          </v-col>
        </v-row>
        <v-row class="justify-center">
          <v-col cols="10" sm="10" style="margin-bottom: 30px">
            <v-btn
              color="primary"
              dark
              elevation="2"
              style="margin-right: 5px"
              @click="addWorkspace()"
              :disabled="!(has_number && has_minimum_length)"
            >
              Opslaan
            </v-btn>
            <v-btn color="danger" dark elevation="2" @click="$router.go(-1)">
              Afsluiten
            </v-btn>
          </v-col>
        </v-row>
      </v-card>
    </v-container>
  </div>
</template>

<script>
import apiOffice from "../api/apiOffice";
import axios from "axios";
import VueUploadMultipleImage from "vue-upload-multiple-image";
export default {
  components: {
    VueUploadMultipleImage,
  },

  data: () => ({
    has_number: false,
    has_minimum_length: false,

    facilities: [],
    categories: [],

    images: [],
    formDataImages: [],
    office: {
      officeName: { value: "" },
      model: "",
      price: { value: "" },
      totalworkspace: "",
      youtube: "",
      facebook: "",
      twitter: "",
      instagram: "",
      description: "",
      square: "",
      facilities: [],
      categories: [],
    },
    AdresData: {
      address: "",
      city: "",
      long_coord: "",
      lat_coord: "",
    },
  }),
  async mounted() {
    apiOffice.getOptions().then((data) => {
      this.facilities = data.facilities;
    });
    apiOffice.getCategories().then((data) => {
      this.categories = data.categories;
    });
  },
  watch: {
    "office.price.value"() {
      this.has_number = /\d/.test(this.office.price.value);
    },
    "office.officeName.value"() {
      this.has_minimum_length = this.office.officeName.value.length > 8;
    },
  },
  methods: {
    Data(AdresData) {
      this.AdresData = {
        adres: AdresData.formatted_address,
        stad: AdresData.vicinity,
        long_coord: AdresData.geometry.location.lng(),
        lat_coord: AdresData.geometry.location.lat(),
      };
    },
    resetForm() {
      this.office.officeName.value = "";
      this.office.facilities = "";
      this.office.categories = "";
      this.office.model = "";
      this.office.price.value = "";
      this.office.total = "";
      this.office.youtube = "";
      this.office.facebook = "";
      this.office.twitter = "";
      this.office.instagram = "";
      this.office.description = "";
      this.AdresData.address = "";
      this.AdresData.long_coord = "";
      this.AdresData.lat_coord = "";
    },

    async addWorkspace() {
      try {
        const payload = {
          officeName: this.office.officeName.value,
          categories: this.office.categories,
          facilities: this.office.facilities,
          model: this.office.model,
          price: this.office.price.value,
          total: this.office.total,
          description: this.office.description,
          square: this.office.square,
          youtube: this.office.youtube,
          facebook: this.office.facebook,
          instagram: this.office.instagram,
          twitter: this.office.twitter,
          info: this.office.description,
          adres: this.AdresData.adres,
          city: this.AdresData.vicinity,
          long_coord: this.AdresData.long_coord,
          lat_coord: this.AdresData.lat_coord,
        };
        const { data } = await axios.post(
          `https://server.kantoorflex.nl/api/rest/v2/office`,
          payload
        );
        await Promise.all(
          this.formDataImages.map(async (formData) => {
            await axios.post(
              `https://server.kantoorflex.nl/api/rest/v2/office/${data.id}/image`,
              formData,
              {
                headers: {
                  "Content-Type": "multipart/form-data",
                },
              }
            );
          })
        );
        this.$swal.fire("Aangemaakt!", "", "success");
        this.resetForm();
        this.$router.push("/Werkplekken");
      } catch (err) {
        console.log(err);
        this.$swal.fire("Controleer of alle velden zijn ingevuld", "", "error");
      }
    },

    uploadImageSuccess(formData, index, fileList) {
      console.log(formData, index, fileList);
      this.$set(this.formDataImages, index, formData);
    },
    beforeRemove(index, done, fileList) {
      console.log("index", index, fileList);
      var r = confirm("remove image");
      if (r == true) {
        done();
      } else {
        this.$Swall.fire("error");
      }
    },
    editImage(formData, index, fileList) {
      console.log("edit data", formData, index, fileList);
      //this.$set(this.formDataImages, index, formData);
    },

    edit(index, item) {
      if (!this.editing) {
        this.editing = item;
        this.editingIndex = index;
      } else {
        this.editing = null;
        this.editingIndex = -1;
      }
    },
    filter(item, queryText, itemText) {
      if (item.header) return false;

      const hasValue = (val) => (val != null ? val : "");

      const text = hasValue(itemText);
      const query = hasValue(queryText);

      return (
        text
          .toString()
          .toLowerCase()
          .indexOf(query.toString().toLowerCase()) > -1
      );
    },
  },
};
</script>

<style scoped>
.space {
  margin-top: 50px;
}
.officeName {
  margin-left: 50px;
  margin-top: 30px;
}
.cards {
  margin-top: 10px;
}
</style>
